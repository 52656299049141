import chat from '@ingka/ssr-icon/paths/chat'
import checkmarkBox from '@ingka/ssr-icon/paths/checkmark-box'
import clock from '@ingka/ssr-icon/paths/clock'
import magnifyingGlass from '@ingka/ssr-icon/paths/magnifying-glass'
import people from '@ingka/ssr-icon/paths/people'

import { useShowPerformanceData } from 'hooks/coworkers/showPerformanceData.hook'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { TabLayout } from 'components/composites/Shared/TabLayout/TabLayout.component'
import { TabDefinitions } from 'components/composites/Shared/TabLayout/TabLayout.types'
import { CoachingSessionPreviewList } from 'components/features/CoachingSessions/CoachingSessionPreviewList'
import EfficencyPanel from 'components/features/Efficiency/EfficiencyPanel.component'
import { EmbedReport } from 'components/features/EmbedReport'
import { MissionPreviewList } from 'components/features/Missions/MissionPreviewList'
import { CustomerCommentsPage } from 'components/pages/shared/CustomerCommentOverviewPage'

import { TabKeys } from './TeamleadTabs.constants'

type TeamleadTabsProps = {
  coworkerId: string
}

const TeamleadTabs: React.FC<TeamleadTabsProps> = ({ coworkerId }) => {
  const { show: showEfficiency, isLoading } = useShowPerformanceData(coworkerId)

  if (isLoading) return <LoadingIndicator />

  const tabsArray: TabDefinitions = [
    ...(showEfficiency
      ? [
          {
            key: TabKeys.Efficiency,
            content: <EfficencyPanel />,
            tKeyForTitle: 'features.1-efficiency.title',
            icon: clock,
            disabled: {
              isDisabled: !showEfficiency,
              tooltipText: 'features.1-efficiency.disabled-tooltip',
            },
          },
        ]
      : []),
    {
      key: TabKeys.Missions,
      content: <MissionPreviewList />,
      tKeyForTitle: 'pages.manage-coworker.tabs.missions.title',
      icon: checkmarkBox,
    },
    {
      key: TabKeys.Explore,
      content: <EmbedReport reportName="top-level-dashboard" pageTitle={TabKeys.Explore} />,
      tKeyForTitle: 'pages.manage-coworker.tabs.explore.title',
      icon: magnifyingGlass,
    },
    {
      key: TabKeys.CoachingSession,
      content: <CoachingSessionPreviewList coworkerId={coworkerId} />,
      tKeyForTitle: 'pages.manage-coworker.tabs.coaching-sessions.title-1',
      icon: people,
    },
    {
      key: TabKeys.CustomerComments,
      content: <CustomerCommentsPage />,
      tKeyForTitle: 'pages.manage-coworker.tabs.customer-comments.title-1',
      icon: chat,
    },
  ]

  return (
    <TabLayout
      tabDefinitions={tabsArray}
      analyticsDescription="Custom event for tracking when a teamlead switches between tabs on coworker page."
    />
  )
}

export default TeamleadTabs
