import InlineMessage from '@ingka/inline-message'
import SSRIcon from '@ingka/ssr-icon'
import info from '@ingka/ssr-icon/paths/information-circle'
import Tooltip from '@ingka/tooltip'
import { radiusS } from '@ingka/variables'
import { Box, Flex, Grid, Group, Stack } from '@mantine/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SurveyMetricRollingAVG } from 'lib/types/surveyMetric.types'

import { useShowPerformanceData } from 'hooks/coworkers/showPerformanceData.hook'
import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'

import { useCSATRollingData } from 'state/slices/api/modules/surveyMetric/surveyMetric.hooks'

import {
  backgroundColourWhite,
  BrandColour,
  colourGreyNeutral2,
  spacing25,
  spacing50,
  spacing100,
} from 'styles/tokens/insikt.tokens'

import { LoadingSkeleton } from 'components/composites/Shared/LoadingSkeleton/LoadingSkeleton.component'
import { Widget } from 'components/composites/Shared/Widget'
import { Paragraph } from 'components/primitives/Text'
import { HelperText, LargeHeading, SubTitleBlue } from 'components/primitives/Text/Text.component'

import CoachingPanel from '../CoachingPanel/CoachingPanel.component'
import AptitudeInfoModal from './AptitudeInfoModal.component'

const span = { base: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }

/* Customer feedback in numbers */
export const AptitudePanel = () => {
  const { t } = useTranslation()
  const coworkerId = useCoworkerId()
  const [openInfo, setOpenInfo] = useState(false)
  const { show: showEfficiency, isLoading: isLoadingSettings } = useShowPerformanceData(coworkerId)

  const {
    computedSurveyValues,
    isFetching,
    isLoading: isLoadingCSAT,
  } = useCSATRollingData(coworkerId)

  const transKey = 'features.aptitude-panel.customer-feedback-banner'
  if (isLoadingCSAT || isLoadingSettings) return <LoadingSkeleton height={'25vh'} />
  if (showEfficiency) return <CoachingPanel coworkerId={coworkerId} />

  return (
    <>
      <Widget
        border
        style={{
          minHeight: '25vh',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!isFetching && (
          <Flex align={'center'} justify={'space-between'} style={{ borderRadius: radiusS }}>
            <Stack gap={0}>
              <SubTitleBlue>
                {t('features.aptitude-panel.customer-feedback-banner.title')}
              </SubTitleBlue>
              <HelperText>
                {t(`${transKey}.helper-text`)}{' '}
                <b style={{ textTransform: 'lowercase' }}>{t(`${transKey}.last-30-days`)}</b>
              </HelperText>
            </Stack>
            <Tooltip
              position="top"
              tooltipText={t('features.aptitude-panel.customer-feedback-banner.tooltip')}
            >
              <SSRIcon
                paths={info}
                onClick={() => setOpenInfo(true)}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </Flex>
        )}

        {!computedSurveyValues && (
          <InlineMessage
            variant="informative"
            title={t('features.aptitude-panel.customer-feedback-banner.no-data')}
          />
        )}

        {/* Aptitude % */}
        {computedSurveyValues && <AptitudeValue computedSurveyValues={computedSurveyValues} />}
      </Widget>

      {/* Aptitude info modal */}
      {openInfo && <AptitudeInfoModal isOpen={openInfo} close={() => setOpenInfo(false)} />}
    </>
  )
}

type AptitudeRollingValueProps = {
  computedSurveyValues: SurveyMetricRollingAVG
}

const AptitudeValue = ({ computedSurveyValues }: AptitudeRollingValueProps) => {
  const {
    percentageManner,
    percentageKnowledge,
    percentageSolution,
    nrTotalManner,
    nrTotalKnowledge,
    nrTotalSolution,
  } = computedSurveyValues
  //Todo: Add translations once we have the official translations for the survey metrics
  const surveyTitles = ['Manner', 'Knowledge', 'Solution']
  const surveyValues = [percentageManner, percentageKnowledge, percentageSolution]
  const numberOfReviews = [nrTotalManner, nrTotalKnowledge, nrTotalSolution]
  return (
    <Grid gutter={spacing50} style={{ width: '100%', height: '100%' }}>
      {surveyTitles?.map((title, index) => (
        <Grid.Col span={span} key={index}>
          <SurveyMetric
            surveyTitle={title}
            surveyValue={surveyValues[index]}
            numberOfReviews={numberOfReviews[index]}
          />
        </Grid.Col>
      ))}
    </Grid>
  )
}

interface SurveyMetricProps {
  surveyTitle: string
  surveyValue: number
  numberOfReviews: number
}

const SurveyMetric = ({ surveyTitle, surveyValue, numberOfReviews }: SurveyMetricProps) => {
  const { t } = useTranslation()
  return (
    <Flex
      direction="column"
      align="stretch"
      justify="space-between"
      p={spacing100}
      bg={colourGreyNeutral2}
      my={spacing50}
      style={{ width: '100%', height: '100%', borderRadius: radiusS }}
    >
      <Stack h={'100%'} align={'flex-start'} justify={'space-evenly'}>
        <Paragraph>{surveyTitle}</Paragraph>
        <Group
          bg={backgroundColourWhite}
          style={{ borderRadius: radiusS }}
          w={'100%'}
          px={spacing25}
          gap={spacing25}
        >
          <LargeHeading>{surveyValue}%</LargeHeading>
        </Group>

        <Group gap={spacing25}>
          <Box bg={BrandColour.Yellow} px={spacing25} style={{ borderRadius: radiusS }}>
            <Paragraph $bold>{numberOfReviews}</Paragraph>
          </Box>
          <Paragraph>{t('features.aptitude-panel.customer-feedback-banner.reviews')}</Paragraph>
        </Group>
      </Stack>
    </Flex>
  )
}
