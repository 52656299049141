import chat from '@ingka/ssr-icon/paths/chat'
import clock from '@ingka/ssr-icon/paths/clock'
import people from '@ingka/ssr-icon/paths/people'
import { Divider } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useShowPerformanceData } from 'hooks/coworkers/showPerformanceData.hook'
import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'
import { useUser } from 'hooks/users'

import { colourGreyNeutral3, spacing50 } from 'styles/tokens/insikt.tokens'

import { GridLayout } from 'components/composites/Shared/GridLayout/GridLayout.component'
import { GridVariant } from 'components/composites/Shared/GridLayout/GridLayout.types'
import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { TabLayout } from 'components/composites/Shared/TabLayout/TabLayout.component'
import { TabDefinitions } from 'components/composites/Shared/TabLayout/TabLayout.types'
import { AptitudePanel } from 'components/features/CoworkerMVP/AptitudePanel/AptitudePanel.component'
import { CoworkerCoachingSession } from 'components/features/CoworkerMVP/CoachingSession/CoworkerCoachingSession.component'
import { ProfileInfo } from 'components/features/CoworkerMVP/ProfileInfo/ProfileInfo.component'
import EfficencyPanel from 'components/features/Efficiency/EfficiencyPanel.component'
import { PageHeader } from 'components/layout/PageHeader'
import { CustomerCommentsPage } from 'components/pages/shared/CustomerCommentOverviewPage'
import { TitleBlue } from 'components/primitives/Text'

const CoworkerLandingPage = () => {
  return (
    <>
      <GridLayout
        noDivider
        children={[
          {
            element: <WelcomeSection />,
            variant: GridVariant.FullLine,
          },
          {
            element: <ProfileInfo />,
            variant: GridVariant.Half,
          },
          {
            element: <AptitudePanel />,
            variant: GridVariant.Half,
          },
        ]}
      />
      <Divider
        size={'xs'}
        color={colourGreyNeutral3}
        style={{
          margin: `${spacing50} 0 ${spacing50} 0`,
        }}
      />

      <CoworkerTabs />
    </>
  )
}

const WelcomeSection = () => {
  const { t } = useTranslation()
  const user = useUser().data
  const [message, setMessage] = useState('')

  useEffect(() => {
    const getGreetingMessage = () => {
      const currentHour = new Date().getHours()
      if (currentHour < 12) {
        return t('features.coworker.welcome-message-morning') + ' ☕️'
      } else if (currentHour < 18) {
        return t('features.coworker.welcome-message-afternoon') + ' ⭐️'
      } else {
        return t('features.coworker.welcome-message-evening') + ' 🌙'
      }
    }

    setMessage(getGreetingMessage())
  }, [t])

  return (
    <PageHeader
      backHref={user?.role === 'admin' ? '/admin/users' : undefined}
      title={<TitleBlue>{message}</TitleBlue>}
    />
  )
}

// TODO: Place for that
enum CoworkerLandingPageTabs {
  CoachingSessions = 'coaching-sessions',
  CustomerComments = 'customer-comments',
  Efficiency = 'efficiency',
}

const CoworkerTabs = () => {
  const id = useCoworkerId()
  const { show: showEfficiency, isLoading } = useShowPerformanceData(id)

  if (isLoading) return <LoadingIndicator />

  const tabDefinitions: TabDefinitions = [
    ...(showEfficiency
      ? [
          {
            key: CoworkerLandingPageTabs.Efficiency,
            content: <EfficencyPanel />,
            tKeyForTitle: 'features.1-efficiency.title',
            icon: clock,
            disabled: {
              isDisabled: !showEfficiency,
              tooltipText: 'features.1-efficiency.disabled-tooltip',
            },
          },
        ]
      : []),
    {
      key: CoworkerLandingPageTabs.CoachingSessions,
      content: <CoworkerCoachingSession />,
      tKeyForTitle: 'features.coaching-session.coaching-sessions',
      icon: people,
    },
    {
      key: CoworkerLandingPageTabs.CustomerComments,
      content: <CustomerCommentsPage />,
      tKeyForTitle: 'features.customer-comments.title',
      icon: chat,
    },
  ]
  return (
    <GridLayout
      noDivider
      gridStyles={{ marginTop: `0` }}
      children={[{ element: <TabLayout tabDefinitions={tabDefinitions} /> }]}
    />
  )
}

export default CoworkerLandingPage
