import { useTranslation } from 'react-i18next'

import { useShowPerformanceData } from 'hooks/coworkers/showPerformanceData.hook'

import FormattedCoworkerNote from 'components/features/CoworkerMVP/CoworkerCreateNote/FormattedCoworkerNote.component'
import EfficencyPanel from 'components/features/Efficiency/EfficiencyPanel.component'
import { NumberedHeader } from 'components/primitives/NumberedHeader'
import { FeatureToggle } from 'components/shared/FeatureToggle'

import ReviewEvaluatedInteractions from './ReviewEvaluatedInteractions/ReviewEvaluatedInteractions.component'
import { ReviewMetricsProps } from './ReviewMetrics.types'

const ReviewMetrics: React.FC<ReviewMetricsProps> = ({ coworkerId, coachingSession }) => {
  const { t } = useTranslation()
  const { show: showEfficiency } = useShowPerformanceData(coworkerId)

  return (
    <>
      <NumberedHeader step={1} title={t('features.coaching-session.review.metrics.title')} />

      {showEfficiency && <EfficencyPanel />}

      <ReviewEvaluatedInteractions coworkerId={coworkerId} />
      <FeatureToggle featureKey="coworkerNew">
        <FormattedCoworkerNote coachingSession={coachingSession} />
      </FeatureToggle>
    </>
  )
}

export default ReviewMetrics
